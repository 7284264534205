import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import moment from 'moment';

import Logo from '../components/logo'

const DATA = require("../../redirect-data.json");
const BASE_URL = "https://www.sam-huang.info";
function jump(url) {
  window && (window.location.href = url);
}

import { graphql, useStaticQuery } from 'gatsby';

/****************************************/

const PINNED_ITEMS = [
  'RevtelIntro',
];

/****************************************/

const SEO = () => {
  const seo = {
    title: "Deploy Your Own Website to Sui Walrus",
    image: BASE_URL + "/images/walrus/og.png",
    description: "Deploy Your Own Website to Sui Walrus",
    url: BASE_URL + '/deploy-your-own-website-to-sui-walrus_en',
  };
  return (
    <Helmet
      title={seo.title}
      meta={[
        { name: "googlebot", content: "noindex" },
        { name: "robots", content: "noindex" },
        {
          name: `description`,
          content: seo.description,
        },
        {
          property: `image`,
          content: seo.image,
        },
        {
          property: `name`,
          content: seo.title,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:url`,
          content: seo.url,
        },
        {
          property: `og:site_name`,
          content: "Deploy Your Own Website to Sui Walrus",
        },
        {
          property: `og:image`,
          content: seo.image,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: "og:url",
          content: seo.url,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: seo.image,
        },
        {
          name: `twitter:creator`,
          content: "Sam Huang,黃奕翔",
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
        {
          property: "keywords",
          content:
            "Sam Huang, Sam, sailplaneTW, 黃奕翔, 賣飛機的, 忻旅科技, Revtel, RevtelTech, 奕果雲端, Eculture, EcultureTech, React, React Native, Inigma, Tel25, Tel-U, 加密鏈, 軟體開發, 軟體顧問, 新創營運, web3, 區塊鏈",
        },
      ]}
    />
  );
};

const MainPage = () => {
  return (
    <Wrapper>
      <SEO />

      <a
        className="action"
        href="/"
        target="_blank"
        style={{
          cursor: "pointer",
          flexDirection: "flow",
          justifyContent: "center",
          alignItems: "center",
          textDecoration: "none",
          color: "black",
        }}
      >
        <a rel="noreferrer" src="/logo5.png" width={60 * 0.9} height={60 * 0.9} alt='Sam Huang 黃奕翔' style={{ borderRadius: '0%' }} />
      </a>

      <div className="content">
        <LangConfig />
        <h1 style={{ fontSize: '3em', textAlign: 'center' }}>Walrus Site Deployment TUTORIAL</h1>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '80%', maxWidth: 1024, minWidth: 240, }}>
          <h2 style={{ color: '#355F2E', fontSize: 36, alignSelf: 'flex-start' }}>[ Preface ]</h2>
          <p>
            Using Web3 concepts to optimize (or even replace) existing Web2 services has always been an important direction in blockchain development.
            In the past, we have often seen many experimental products emerge in the market, but product development was never a simple task, and many attempts easily fade away after sparking initial discussions.
          </p>
          <p>The other day on X, I saw that Walrus (<a rel="noreferrer" style={{ display: 'inline-block', whiteSpace: 'nowrap' }} href='https://www.walrus.xyz/' target="_blank">https://www.walrus.xyz/</a>), a decentralized storage service, can be used for website deployment. After trying it out, I found it quite smooth, and I&apos;m writing this simple record with the goal of deploying a website on Walrus.</p><br />
          <p style={{ alignSelf: 'flex-start' }}>
            Note<br />
            - This content is performed using Mac, and if you are using a different environment, you can refer to the official documentation (<a rel="noreferrer" style={{ display: 'inline-block' }} href='https://docs.walrus.site/walrus-sites/intro.html' target='_blank'>https://docs.walrus.site/walrus-sites/intro.html</a>) for modifications. However, the concepts and steps are basically the same<br />
            - Currently (2024/12/01), Walrus Site is still on testnet, and the content in this article may not be applicable when it is officially launched on mainnet</p>

          <img rel="noreferrer" onClick={() => window.open('https://sam-huang-website.walrus.site/', '_blank')} src='/images/walrus/1.png' style={{ width: '80%', alignSelf: 'center', cursor: 'pointer' }} />
          <a rel="noreferrer" href='https://sam-huang-website.walrus.site/' target='_blank' style={{ alignSelf: 'center' }}>https://sam-huang-website.walrus.site/</a>





          <h2 style={{ color: '#355F2E', fontSize: 36, alignSelf: 'flex-start' }}>[ Deploying a Website to Walrus ]</h2>
          <h3 style={{ alignSelf: 'flex-start', fontSize: 24, marginTop: -10 }}>1. Environment Setup: The goal is to enable your computer to deploy websites to Walrus</h3>
          <h4 style={{ marginTop: -10 }}>1.1 Install Rust Package Manager Cargo</h4>
          <div style={{ backgroundColor: '#F1F0E8', width: '100%', padding: 10, marginTop: -10 }}>
            brew install rustup<br />
            rustup-init
          </div>
          <h4 style={{}}>1.2 Install Sui CLI</h4>
          <div style={{ backgroundColor: '#F1F0E8', width: '100%', padding: 10, marginTop: -10 }}>
            brew install sui
          </div>
          <h4 style={{}}>1.3 Switch Environment to Testnet</h4>
          <div style={{ backgroundColor: '#F1F0E8', width: '100%', padding: 10, marginTop: -10 }}>
            sui client new-env --alias testnet --rpc https://fullnode.testnet.sui.io:443<br />
            sui client switch --env testnet
          </div>
          <h4 style={{}}>1.4 Install Walrus</h4>
          <p rel="noreferrer" style={{ marginTop: -10 }}>Here, use the officially pre-compiled binary <a rel="noreferrer" style={{ display: 'inline-block' }} href='https://mystenlabs.github.io/walrus-docs/usage/setup.html' target='_blank'>https://mystenlabs.github.io/walrus-docs/usage/setup.html</a></p>
          <img rel="noreferrer" src='/images/walrus/2.png' style={{ width: '80%', alignSelf: 'center' }} />
          After downloading, rename the file to walrus and add it to the $PATH
          <h4 style={{}}>1.5 Obtain Sui on Testnet</h4>
          <div style={{ backgroundColor: '#F1F0E8', width: '100%', padding: 10, marginTop: -10 }}>
            sui client faucet
          </div>
          Of course, you can also switch your account to the Sui wallet you frequently use (through commands like sui key import / sui client switch)          <br />
          <br />

          <h3 style={{ alignSelf: 'flex-start', fontSize: 24, }}>2. Website Deployment: The purpose is to deploy a specified website to Walrus</h3>
          <h4 style={{ marginTop: -10 }}>2.1 Download and Install the Official Walrus-Sites</h4>
          <div style={{ backgroundColor: '#F1F0E8', width: '100%', padding: 10, marginTop: -10 }}>
            git clone https://github.com/MystenLabs/walrus-sites.git<br />
            cd walrus-sites<br />
            cargo build --release
          </div>
          <h4 style={{}}>2.2 Deploy the Snake Game Website from Walrus-Sites Example Code</h4>
          <div style={{ backgroundColor: '#F1F0E8', width: '100%', padding: 10, marginTop: -10 }}>
            ./target/release/site-builder publish ./examples/snake --epochs 100
          </div>
          <h4 style={{}}>2.3 Obtain Deployed Website URL</h4>
          <img rel="noreferrer" src='/images/walrus/3.png' style={{ width: '80%', alignSelf: 'center' }} />
          <p>The red area in the image is the deployed website URL <a rel="noreferrer" href='https://2wrfk752zp9l8x1csitemjl0tom9duh25weja8eoqu5puapb6f.walrus.site/' target='_blank'>https://2wrfk752zp9l8x1csitemjl0tom9duh25weja8eoqu5puapb6f.walrus.site/</a>. You can try opening it in your browser to see if the website loads!</p>
          The object ID 0x74c1ede4c194f938674b244c060e70130d75e876b00dba316bbb470b637756d7 in the image is the resource ID of this website on SUI
          <img rel="noreferrer" src='/images/walrus/4.png' style={{ width: '80%', alignSelf: 'center' }} />

          <br />
          <br />
          <h3 style={{ alignSelf: 'flex-start', fontSize: 24, }}>3. Subdomain Name Setting: The Purpose is to Make the Website URL More Readable</h3>
          <p style={{ marginTop: -10 }}> <a rel="noreferrer" href='https://2wrfk752zp9l8x1csitemjl0tom9duh25weja8eoqu5puapb6f.walrus.site/' target='_blank'>https://2wrfk752zp9l8x1csitemjl0tom9duh25weja8eoqu5puapb6f.walrus.site/</a> is a difficult-to-read URL, which we can improve by using the Sui Name Service to assign a meaningful name.</p>
          <h4 style={{}}>3.1 Purchase a SuiNS Name: https://testnet.suins.io/</h4>
          <img rel="noreferrer" onClick={() => window.open('https://testnet.suins.io/', '_blank')} src='/images/walrus/5.png' style={{ width: '80%', alignSelf: 'center', cursor: 'pointer' }} />
          <a rel="noreferrer" href='https://testnet.suins.io/' target='_blank' style={{ alignSelf: 'center' }}>https://testnet.suins.io/</a>
          <h4 style={{}}>3.2 Link the Purchased SuiNS Name to the Website&apos;s Object ID</h4>
          <img rel="noreferrer" src='/images/walrus/6.png' style={{ width: '80%', alignSelf: 'center', cursor: 'pointer' }} />
          <img rel="noreferrer" src='/images/walrus/7.png' style={{ width: '80%', alignSelf: 'center', cursor: 'pointer' }} />
          <p>Now you can access the previously deployed website using the purchased domain <a href='https://my-walrus-test.walrus.site/' rel="noreferrer" target="_blank">https://my-walrus-test.walrus.site/</a>!</p>








          <br />
          <br />
          <h2 style={{ color: '#355F2E', fontSize: 36, alignSelf: 'flex-start' }}>[ Conclusion ]</h2>
          <p>
            In fact, the Walrus website hosting service has been online for quite some time now, and I&apos;m realizing this a bit late. <br /><br />
            Similar to how AI has become a marketing buzzword in recent years, seemingly promising a bright future just by applying its label — the same phenomenon has been frequently observed in blockchain development. As a new type of network infrastructure with inherent economic attributes, blockchain offers developers and users many possibilities that require more services like Walrus.
          </p>

        </div>
      </div>
      <a
        className="action"
        href="/"
        target="_blank"
        style={{
          cursor: "pointer",
          flexDirection: "flow",
          justifyContent: "center",
          alignItems: "center",
          textDecoration: "none",
          color: "black", padding: 10
        }}
      >
        <img src='/logo6.png' width='120' />

      </a>
    </Wrapper>
  );
};

const LangConfig = () => {
  return (
    <div style={{ display: 'flex', padding: 5, backgroundColor: '#7BD3EA', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <a style={{ textDecoration: 'none', color: 'white', fontWeight: '600', fontSize: 24, width: 48, height: 48, borderRadius: 5, backgroundColor: '#D4EBF8', marginRight: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }} href='/deploy-your-own-website-to-sui-walrus/'>中</a>
      <a style={{ textDecoration: 'none', color: 'white', fontWeight: '600', fontSize: 24, width: 48, height: 48, borderRadius: 5, backgroundColor: '#0A3981', display: 'flex', justifyContent: 'center', alignItems: 'center' }} href='/deploy-your-own-website-to-sui-walrus_en/' > EN</a>
    </div >
  )
}


const Wrapper = styled.div`
    min-height: 98vh;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > .action {
        display: flex;
        flex-direction: row;
        //padding: 15px;
        width:100%;
        height:64px;
        background-color: #EEEEEE;
    }

    & > .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex:1;
        width:100%;
        padding-top:50px;
        padding-bottom:50px;
        //background-color: #FEFCF3;
    }
`;

export default MainPage;
